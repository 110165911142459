import React from 'react';
import Button from '@mui/material/Button';

const ButtonOutlined = ({ children, sx, ...props }) => {
  return (
    <Button
      sx={{
        border: '3px solid #FF8A00',
        borderRadius: 0,
        paddingX: '20px',
        '&:hover': {
          backgroundColor: '#FF8A00',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonOutlined;

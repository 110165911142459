import React from 'react';
import Typography from '@mui/material/Typography';
import useIsMobile from '../hooks/useIsMobile';

const Header1 = ({ children, sx, ...props }) => {
  const isMobile = useIsMobile();
  
  return (
    <Typography sx={{
      color: 'white',
      fontSize: isMobile ? '32px' : '48px',
      fontWeight: '600',
      ...sx,
    }}
    {...props}
    >
      {children}
    </Typography>
  );
};

export default Header1;

import React from 'react';
import Typography from '@mui/material/Typography';

const Title = ({ children, sx, ...props }) => {
  
  return (
    <Typography sx={{
      color: 'white',
      fontSize: {
        xs: '40px', // extra-small devices
        sm: '48px', // small devices
        md: '64px', // medium devices
        lg: '80px', // large devices
        xl: '96px', // extra-large devices
      },
      fontWeight: '700',
      lineHeight: 1.25,
      //textShadow: '2px 2px 4px #000',
      ...sx,
    }}
    {...props}
    >
      {children}
    </Typography>
  );
};

export default Title;

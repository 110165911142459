import { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, FormControl } from '@mui/material';
import useIsMobile from './hooks/useIsMobile';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import emailjs from 'emailjs-com';

import AstronautImage from './images/OrangeAstronaut.png';
import PhotoshopLogo from './images/Photoshop.png';
import FigmaLogo from './images/Figma.png';
import ReactLogo from './images/React.png';
import NodejsLogo from './images/Node.png';
import CadeLinkedIn from './images/CadeLinkedIn.jpg';


import ButtonOutlined from './components/ButtonOutlined';
import Header1 from './components/Header1';
import Title from './components/Title';
import NavBar from './components/Navbar';
import MobileNavMenu from './components/MobileNavMenu';
import Paragraph1 from './components/Paragraph1';
import Paragraph2 from './components/Paragraph2';
import Portrait from './components/Portrait';
import Check from './components/Check';
import { ContactPhoneOutlined } from '@mui/icons-material';

function App() {
  const isMobile = useIsMobile();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const toggleMobileNav = () => setIsMobileNavOpen(!isMobileNavOpen);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({
    email: false,
    phone: false,
    firstName: false,
    lastName: false
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitDisabled(true);
    setTimeout(() => setIsSubmitDisabled(false), 3000)

    const newErrors = {
      email: !/\S+@\S+\.\S+/.test(formData.email),
      phone: !( /^(\+1\s?)?(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)?\d{4}$/.test(formData.phone) || formData.phone.length === 0 ),
      firstName: formData.firstName.length === 0,
      lastName: formData.lastName.length === 0
    };
  
    // Update the formErrors state with the newErrors object
    setFormErrors(newErrors);

    if (Object.values(newErrors).some(error => error)) {
      return;
    }

    const { serviceID, templateID, userID } = {
      serviceID: process.env.REACT_APP_EMAILJS_SERVICE_ID,
      templateID: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      userID: process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    };
  
    emailjs.send(serviceID, templateID, {
      from_email: formData.email,
      from_phone: formData.phone,
      first_name: formData.firstName,
      last_name: formData.lastName,
      message: formData.message,
    }, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Your message was sent successfully');
        window.location.href = 'https://www.astronaut.site/#submit'
      }, (err) => {
        console.log('FAILED...', err);
        alert('Failed to send the message, please try again.');
      });

  };

  return (
    <>
    <NavBar onMenuButtonPressed={toggleMobileNav} />
    <MobileNavMenu isVisible={isMobileNavOpen} onButtonPress={toggleMobileNav}/>

    <Box id='hero'
      sx={{
        // Sizing
        height: '100vh',
        paddingTop: '75px',
        paddingBottom: isMobile ? '110px' : '50px',
        marginBottom: isMobile? '150px' : '300px',
        paddingX: isMobile ? "25px" : "50px",
        // Styling
        backgroundColor: 'black',
        zIndex: 2,
        // Children
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    }}>

      <img style={{
          position: 'absolute',
          top: '60%',
          left: '50%',
          transform: 'translate(-55%, -65%)',
          maxWidth: '95vw', // Prevent horz scroll
          maxHeight: '85vh',
          height: 'auto',
        }}
        src={AstronautImage}
        alt="Astronaut"
      />

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        gap: isMobile ? '25px' : '0px',
        width: '100%',
        height: '100%',
        zIndex: 1,
      }}>

        <Title>
          Web Development<br />Services
        </Title>

        <Box sx={{ alignSelf: 'center', }}>
          <ButtonOutlined 
            href='#contact'
            size='large'
          >
            Contact
          </ButtonOutlined>
        </Box>

      </Box>
    </Box>

    <Box id='services'
      sx={{
        // Sizing
        //minHeight: '100vh',
        paddingY: isMobile ? '150px' : '300px',
        paddingX: isMobile ? '25px' : '50px',
        maxWidth: '1300px',
        marginX: 'auto',
        // Styling
        backgroundColor: 'black',
        // Children
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Vertically center
        alignItems: 'flex-start', // Horizontally left
        gap: '50px',
      }}
    >
      <Box>
        <Header1>
          We design and build websites and web apps from scratch using the latest technologies
        </Header1>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
        <Paragraph1><Check/> User Interface Design</Paragraph1>
        <Paragraph1><Check/> User Interface Implementation</Paragraph1>
        <Paragraph1><Check/> Custom Software Development</Paragraph1>
        <Paragraph1><Check/> Maintenance and Updates</Paragraph1>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '25px', alignItems: 'center' }}>
        <img src={PhotoshopLogo} alt="Photoshop Logo" style={{ width: '42px' }} />
        <img src={FigmaLogo} alt="Figma Logo" style={{ width: '40px' }} />
        <img src={ReactLogo} alt="React Logo" style={{ height: '50px' }} />
        <img src={NodejsLogo} alt="Node.js Logo" style={{ height: '32px' }} />
      </Box>
    </Box>

    <Box id='about'
      sx={{
        // Sizing
        //minHeight: '100vh',
        paddingY: isMobile ? '150px' : '300px',
        paddingX: isMobile ? '25px' : '50px',
        maxWidth: '1300px',
        marginX: 'auto',
        // Styling
        backgroundColor: 'black',
        // Children
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Vertically center
        alignItems: 'flex-start', // Horizontally left
        gap: isMobile ? '50px' : '50px',
      }}
    >
      <Box>
        <Header1>
          Access a team of experienced designers and developers
        </Header1>
      </Box>

      <Box>
        <Paragraph1 sx={{
          lineHeight: 2,
        }}>
          We are experienced designers and developers who are dedicated to 
          bringing your vision to life with innovative and high-quality solutions
        </Paragraph1>
      </Box>
    </Box>

    <Box id='contact'
    sx={{
      // Sizing
      minHeight: '100vh',
      paddingX: isMobile ? "25px" : "50px",
      paddingY: isMobile ? '150px' : '300px',
      maxWidth: '1300px',
      marginX: 'auto',
      // Styling
      backgroundColor: 'black',
      // Children
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      gap: '50px',
    }}>
      <Typography style={{
        color: 'white',
        fontSize: isMobile ? '36px' : '48px',
        fontWeight: '700',
        lineHeight: 1.25,
        textAlign: 'center',
      }}>
        Let's Launch Something<br />Great Together
      </Typography>

      <Paragraph1>
        Only Accepting 1 New Client Per Month
      </Paragraph1>

      <FormControl fullWidth component="form" onSubmit={handleSubmit} variant="outlined">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px', marginX: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '25px' }}>
            <TextField
              required
              name="firstName"
              label="First Name"
              variant="outlined"
              value={formData.firstName}
              onChange={handleFormChange}
              error={formErrors.firstName}
              fullWidth
            />
            <TextField
              required
              name="lastName"
              label="Last Name"
              variant="outlined"
              value={formData.lastName}
              onChange={handleFormChange}
              error={formErrors.lastName}
              fullWidth
            />
          </Box>
          <TextField
            required
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            value={formData.email}
            onChange={handleFormChange}
            error={formErrors.email}
            fullWidth
          />
          <TextField
            name="phone"
            label="Phone Number"
            type="tel"
            variant="outlined"
            value={formData.phone}
            onChange={handleFormChange}
            error={formErrors.phone}
            fullWidth
          />
          <TextField
            name="message"
            label="Message"
            multiline
            rows={4}
            variant="outlined"
            value={formData.message}
            onChange={handleFormChange}
            fullWidth
          />

          <Box sx={{ marginTop: '25px'}}>
            <ButtonOutlined type='submit' size='large' onClick={handleSubmit} disabled={isSubmitDisabled}>Submit</ButtonOutlined>
          </Box>

        </Box>
      </FormControl>
    </Box>

    </>
  )
  
}

export default App;

import React from 'react';
import Typography from '@mui/material/Typography';
import useIsMobile from '../hooks/useIsMobile';

const Paragraph1 = ({ children, sx, ...props }) => {
  const isMobile = useIsMobile();
  
  return (
    <Typography sx={{
      color: 'white',
      fontSize: isMobile ? '20px' : '24px',
      fontWeight: '400',
      ...sx,
    }}
    {...props}
    >
      {children}
    </Typography>
  );
};

export default Paragraph1;

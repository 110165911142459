import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonOutlined from './ButtonOutlined';
import useIsMobile from '../hooks/useIsMobile';

const MobileNavMenu = (props) => {
  const { isVisible, onButtonPress } = props
  const isMobile = useIsMobile();

  return (
    isVisible ? (
      <Box sx={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'center',
        alignItems: 'center',
        gap: '50px',
        backgroundColor: 'black',
        height: '100vh',
        width: '100vw',
        paddingTop: '75px',
        paddingBottom: isMobile ? '60px' : '',
        zIndex: 3,
      }}>
        <Button sx={{ width: 'auto', maxWidth: '90%' }} href="#services" onClick={onButtonPress}>Services</Button>
        <Button sx={{ width: 'auto', maxWidth: '90%' }} href="#about" onClick={onButtonPress}>About</Button>
        <Button sx={{ width: 'auto', maxWidth: '90%' }} href="#contact" onClick={onButtonPress}>Pricing</Button>
        <ButtonOutlined sx={{ width: 'auto', maxWidth: '90%' }} href="#contact" onClick={onButtonPress}>Contact</ButtonOutlined>
      </Box>
    ) : (
      null
    )
    
  );
};

export default MobileNavMenu;

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonOutlined from './ButtonOutlined'; // Assuming you have an ButtonOutlined component
import useIsMobile from '../hooks/useIsMobile';

const NavBar = (props) => {
  const { onMenuButtonPressed } = props;
  const isMobile = useIsMobile();

  return (
    <AppBar position="fixed" sx={{ background: 'black', borderBottom: '1px solid white', }}>
      <Toolbar 
        sx={{
          height: isMobile ? "75px" : "75px",
          marginX: isMobile ? '25px' : '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ fontSize: isMobile ? '24px' : '32px', fontWeight: '600', marginRight: '20px' }}>
            Astronaut
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: '32px'}}>
          {isMobile ? (
            <ButtonOutlined
            onClick={onMenuButtonPressed}
            sx={{
              backgroundColor: '#FF8A00',
              border: 'none',
            }}>
              Menu
            </ButtonOutlined>
          ) : (
            <>
              <Button href="#services">Services</Button>
              <Button href="#about">About</Button>
              <Button href="#contact">Pricing</Button>
              <ButtonOutlined href="#contact">Contact</ButtonOutlined>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;

import { useState, useEffect } from 'react';

// Custom hook to check if the screen width is less than a given threshold
function useIsMobile(threshold = 900) {
  // Initialize state with current window width
  const [isMobile, setIsMobile] = useState(window.innerWidth < threshold);

  useEffect(() => {
    // Function to update state based on current window width
    const handleResize = () => {
      setIsMobile(window.innerWidth < threshold);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [threshold]); // Only re-run the effect if the threshold changes

  return isMobile;
}

export default useIsMobile;
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,   // small phones
      sm: 600, // phones
      md: 960, // tablets
      lg: 1280, // small laptop screens
      xl: 1920, // desktop screens
    },
  },
  palette: {
    text: {
      primary: '#ffffff', // Sets default text color to white
    },
    background: {
      default: 'black', // Sets default background color to black
    },
    error: {
      main: '#FF8A00', // Orange color used for error states
    }
  },
  typography: {
    margin: 0,
    padding: 0,
    color: 'white',
    fontFamily: 'Orbitron, Arial, sans-serif', // Customized font family with fallbacks
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          'html, body': {
            backgroundColor: 'black',
            color: 'white',
            scrollBehavior: 'smooth',
          },
          body: {
            margin: 0,
            padding: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: '16px',
          color: 'white', // Ensure your button's background contrasts well with white text
          textTransform: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '0 !important', // Removes padding-left
          paddingRight: '0 !important', // Removes padding-right
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // Remove the box shadow
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: { color: 'white' }
        },
        InputProps: {
          style: { color: 'white', borderColor: 'white' }
        }
      },
      styleOverrides: {
        root: {
          '&.Mui-error': {
            '& input': {
              color: '#FF8A00', // Text color when in error
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF8A00', // Border color when in error
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF8A00', // Border color on hover when in error
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF8A00', // Border color when focused and in error
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // Default border color
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // Border color on hover
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // Border color when focused
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF8A00', // Border color when in error
          },
          '&.Mui-error input': {
            color: 'white', // Text color when in error
          },
        },
        input: {
          color: 'white', // Sets text color inside the input
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'white', // Default color
          '&.Mui-focused': {
            color: 'white', // Color when the label is focused
          },
          '&.Mui-error': {
            color: '#FF8A00', // Label color when in error
          }
        }
      }
    }
  },
});

export default theme;
